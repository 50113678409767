import * as React from "react";
import {
  Flex,
  Box,
  Text,
  Icon,
  Tooltip,
  LinkBox,
  useColorModeValue,
  StepTitle,
} from "@chakra-ui/react";
import Card from "./Card";
import {
  BaseItem,
  DerivativeItem,
  IndexItem,
  StakingItem,
  StatusType,
} from "../types";
import TokenLabel, { TOKEN_INFO_MAP } from "./TokenLabel";
import { NEOPIN_DAPP_LINK } from "../config/constants/common";
import { QuestionIcon } from "@chakra-ui/icons";
import QuestionTooltip from "./QuestionTooltip";
import { Link } from "react-router-dom";
import ICON_MEMBERSHIP from "../assets/img/icons/ic_membership.svg";
import ICON_GOVERNANCE from "../assets/img/icons/ic_governance.svg";
import { removeDecimalZero } from "../config/constants/numbers";
import TooltipWrapper from "./TooltipWrapper";
import { useThemeColors } from "../hooks/useThemeColors";
type ListType = "staking" | "liquidStaking" | "derivative" | "index";
type DataType = StakingItem[] | DerivativeItem[] | IndexItem[];

interface SectionProps {
  title: string;
  subTitle?: string;
  data?: DataType;
  type: ListType;
  link?: string;
}

const CardSection: React.FC<SectionProps> = ({
  title,
  data,
  type,
  link,
  subTitle,
}) => {
  const { color } = useThemeColors();
  const handleButtonClick = (
    item: StakingItem | DerivativeItem | IndexItem
  ) => {
    const { chain, name, cate } = item;
    const baseUrl = NEOPIN_DAPP_LINK;
    const url =
      type === "index"
        ? `${baseUrl}/index/detail/?chain=${chain}&name=${name}`
        : type === "liquidStaking" || type === "staking"
        ? `${baseUrl}/staking/detail/?chain=${chain}&name=${name}`
        : type === "derivative"
        ? `${baseUrl}/derivatives/detail/?cate=${cate}&name=${name}`
        : "";
    window.open(url, "_blank");
  };

  const renderCard = (
    item: StakingItem | DerivativeItem | IndexItem,
    index: number
  ) => {
    const commonList = [
      { title: "Network", value: <TokenLabel tokenSymbol={item.chain} /> },
      {
        title:
          type === "index" ? (
            <Flex alignItems={"center"} gap={"5px"}>
              <Text>Price</Text>
              <QuestionTooltip
                label={
                  "The estimated price is updated in every 10 minutes and there may be a difference between the live time price."
                }
              />
            </Flex>
          ) : (
            <Flex alignItems={"center"} gap={"5px"}>
              <Text>
                {type === "staking" && item.displayName.en !== "ETH"
                  ? "Est. APR"
                  : "Est. APY"}
              </Text>
              <QuestionTooltip
                label={
                  type === "staking" && item.displayName.en !== "ETH"
                    ? `Estimated Annual Percentage Rate varies by the membership tiers. \n ${
                        (item as StakingItem).stakeToken.symbol
                      } : ${removeDecimalZero(
                        (item as StakingItem).rewardInfo?.rewardApr?.toFixed(
                          2
                        ) ?? "0"
                      )}% \n ${
                        (item as StakingItem).membershipBonusInfo?.bonusToken
                          ?.symbol
                      } (membership) : ${removeDecimalZero(
                        (
                          item as StakingItem
                        ).membershipBonusInfo?.minBonusApr?.toFixed(2)
                      )}% ~ ${removeDecimalZero(
                        (
                          item as StakingItem
                        ).membershipBonusInfo?.maxBonusApr?.toFixed(2)
                      )}%`
                    : type === "liquidStaking"
                    ? "Estimated Annual Percentage Yield (APY) is 7-day-averaged and updated daily basis. APY is calculated by calculating amount of rewards that actual users receive in year. It is changeable according to the network condition."
                    : "This is an maximum estimated reward rate that can be calculated based on the 7-day average APY of the linked protocols, and may differ from the actual APY due to slippage incurred in the execution of transactions. This estimate is provided daily and may fluctuate depending on the protocol and network conditions."
                }
              />
            </Flex>
          ),
        value: (
          <Box display={"flex"} gap={"5px"} alignItems={"center"}>
            {type === "staking" && (
              <>
                {(item as StakingItem).rewardInfo?.rewardToken?.symbol ===
                  "NPT" && (
                  <TooltipWrapper
                    label={`${
                      (item as StakingItem).stakeToken?.symbol
                    } staking products include governance voting power proportional to the amount staked, allowing stakers to delegate and participate in key decisions of ${
                      TOKEN_INFO_MAP[item.chain]?.fullName
                    } ecosystem on behalf of NEOPIN.`}
                  >
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={"10px"}
                      background={"gray.900"}
                      w={"16px"}
                      h={"16px"}
                    >
                      <img src={ICON_GOVERNANCE} />
                    </Flex>
                  </TooltipWrapper>
                )}
                <img src={ICON_MEMBERSHIP} />
              </>
            )}
            {type === "index"
              ? item.stat === StatusType.ING
                ? `$${(item as IndexItem).price?.toFixed(2)}`
                : "TBD"
              : type === "derivative"
              ? "Up to " + (item as DerivativeItem).apyRate?.toFixed(2) + "%"
              : type === "staking"
              ? "Up to " + (item as DerivativeItem).aprRate?.toFixed(2) + "%"
              : (item as StakingItem).aprRate?.toFixed(2) + "%"}
          </Box>
        ),
      },
      {
        title:
          type === "index" ? (
            <Flex alignItems={"center"} gap={"5px"}>
              <Text>24H%</Text>
              <QuestionTooltip
                label={
                  "Percentage change of the asset price in 24 hours. It depends on the price of component tokens."
                }
              />
            </Flex>
          ) : (
            "Rewards"
          ),
        value:
          item.stat === StatusType.ING ? (
            type === "index" ? (
              <Text
                color={
                  (item as IndexItem).priceChange24HRate >= 0
                    ? "rgb(71, 225, 100)"
                    : "#FF4561"
                }
              >
                {(item as IndexItem).priceChange24HRate >= 0 ? "+" : ""}
                {(item as IndexItem).priceChange24HRate?.toFixed(2)}%
              </Text>
            ) : (
              <Text>
                {(item as StakingItem).rewardInfo?.rewardToken?.symbol ||
                  (item as DerivativeItem).rewardToken?.[0]?.symbol}{" "}
                {type === "staking" &&
                  (item as StakingItem).rewardInfo?.rewardToken?.symbol !==
                    "NPT" &&
                  `+ NPT`}
              </Text>
            )
          ) : (
            <Text>-</Text>
          ),
      },
    ];

    return (
      <Card
        isAppOnly={item.chain === "KLAY" && !item.isWebSupported}
        key={index}
        title={item.displayName.en}
        img={
          type === "index"
            ? [(item as IndexItem).liquidToken[0]?.imageUrl]
            : item?.imageURL
        }
        subtitle={
          type === "index" || type === "derivative"
            ? (item as IndexItem).description
            : (item as StakingItem).stakeToken?.name
        }
        onClick={() => handleButtonClick(item)}
        list={commonList}
        buttonTitle={
          type === "index"
            ? "Buy"
            : type === "derivative"
            ? "Deposit"
            : "Stake Now"
        }
        isComingSoon={item?.stat === StatusType.READY}
      />
    );
  };
  return (
    <Flex
      gap="20px"
      width="100%"
      flexDirection="column"
      justifyContent="center"
      py="20px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ base: "flex-start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        gap={{ base: "12px", md: "0px" }}
      >
        <Flex direction={"column"} gap={"8px"}>
          <Text as="h2" textStyle={{ base: "title20SB", md: "title28SB" }}>
            {title}
          </Text>
          {subTitle && (
            <Text
              textStyle={{ base: "body14R", md: "body20R" }}
              color={color.text.quaternary}
            >
              {subTitle}
            </Text>
          )}
        </Flex>
        {NEOPIN_DAPP_LINK && link && (
          <Link to={NEOPIN_DAPP_LINK + link}>
            <Text
              textStyle={{ base: "button14M", md: "title1" }}
              color="gray.400"
            >
              More
            </Text>
          </Link>
        )}
      </Box>
      <Box
        w={"100%"}
        overflowX={{ base: "auto", md: "visible" }}
        p={{ base: "30px", md: "0px" }}
        pl={{ base: "0px", md: "0px" }}
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&": {
            scrollbarWidth: "none" /* Disable scrollbar */,
            scrollbarColor:
              "transparent transparent" /* Hide the scrollbar track and thumb */,
          },
          "-ms-overflow-style": "none" /* IE and Edge */,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          gap="20px"
          minWidth={"1200px"}
        >
          {(data as DataType)
            ?.sort((a, b) => {
              const orderA =
                "stakingOrder" in a && a.stakingOrder !== undefined
                  ? a.stakingOrder
                  : (a as IndexItem | DerivativeItem).order;
              const orderB =
                "stakingOrder" in b && b.stakingOrder !== undefined
                  ? b.stakingOrder
                  : (b as IndexItem | DerivativeItem).order;
              return orderA - orderB;
            })
            ?.filter(({ stat }) => stat !== 4 && stat !== 0)
            .slice(0, 4)
            .map(renderCard)}
          {Array.from({ length: 4 - (data?.slice(0, 4).length || 0) }).map(
            (_, index) => (
              <Box
                key={`empty-${index}`}
                position="relative"
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                borderRadius="10px"
                boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.04)"
                border="1px dashed"
                borderColor={color.border.secondary}
                p="40px 16px 16px"
                color="gray.600"
                textStyle="body7"
              >
                More to come
              </Box>
            )
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default CardSection;
