export const PARTNERS = [
  { name: "Polygon" }, // "polygon" (match)
  { name: "Kaia" }, // "Kaia" (match)
  { name: "VASP" }, // "VerifyVASP" (match)
  { name: "NPY" }, // "NEOWIZ PARTNERS" (match)
  { name: "nwz" }, // "NEOWIZ" (match)
  { name: "EOS" }, // "EOSSeoul" (match)
  { name: "Finschia" }, // "Finschia" (match)
  { name: "SBINFT" }, // "SBINFT" (match)
  { name: "kana" }, // "kanalabs" (partial match: kana)
  { name: "PT" }, // "PT. Pelayaran Korindo" (match)
  { name: "ticker" }, // "ticker capital" (match)
  { name: "Pala" }, // "Pala" (match)
  { name: "kana" }, // "kana" (match)
  { name: "lena" }, // "lena" (match)
  { name: "searchfi" }, // "SEARCHFI" (match)
  { name: "miracle" }, // "MIRACLE PLAY" (partial match: miracle)
  { name: "perplay" }, // "PERPLAY" (match)
  { name: "lilli" }, // "lillitte" (partial match: lilli)
  { name: "trip" }, // "Tripbtoz" (partial match: trip)
  { name: "elysia" }, // "ELYISA" (partial match: elysia)
  { name: "yooldo" }, // "YOOLDO" (match)
  { name: "magmabyte" }, // "MAGMABYTE" (match)
  { name: "xticket" }, // "X-TICKET" (match)
  { name: "havah" }, // "HAVAH" (match)
  { name: "agate" }, // "Agaté." (match)
  { name: "cabinet" }, // "Cabinet" (match)
  { name: "meverse" }, // "MEVERSE LABS" (partial match: meverse)
  { name: "bloom" }, // "Bloomingbit" (partial match: bloom)
  { name: "stat" }, // "STAT" (match)
  { name: "post" }, // "POST VOYAGER" (partial match: post)
  { name: "oneplanet" }, // "OnePlanet" (match)
  { name: "tangle" }, // "Tangled" (match)
  { name: "drawshop" }, // "DRAWSHOP KINGDOM" (partial match: drawshop)
  { name: "metabeat" }, // "META BEAT" (partial match: metabeat)
  { name: "cube" }, // "CUBE" (match)
  { name: "ssx" }, // "SOMESING" (no match: ssx used as placeholder)
  { name: "sheepfarm" }, // "SHEEPFARM" (match)
  { name: "kns" }, // "KNS" (match)
  { name: "pilab" }, // "PILAB" (match)
  { name: "3pa" }, // "3SPACE ART" (partial match: 3pa)
  { name: "epic" }, // "EPIC LEAGUE" (partial match: epic)
  { name: "klaymeta" }, // "KLAYMETA" (match)
  { name: "klaydice" }, // "klaydice" (match)
  { name: "jeff" }, // "JEFF" (match)
  { name: "coinness" }, // "CoinNess.com" (match)
  { name: "lazy" }, // "CLUB" (no match: lazy used as placeholder)
  { name: "preto" }, // "Presto" (partial match: preto)
  { name: "ozys" }, // "ozys" (match)
  { name: "boomco" }, // "boomco" (match)
  { name: "hyperithm" }, // "hyperithm" (match)
  { name: "pixelplay" }, // "PIXEL PLAY" (match)
  { name: "catalyze" }, // "Catalyze" (match)
  { name: "patex" }, // "patex" (match)
];
