// src/components/Card.tsx
import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  useColorMode,
  useColorModeValue,
  Flex,
  Link,
  Icon,
  ButtonGroup,
} from "@chakra-ui/react";

import StakingKeyValueSet from "./StakingKeyValueSet";
import TokenIcon from "./TokenIcon";
import { mode } from "@chakra-ui/theme-tools";
import { GradientBox } from "./GradientBox";
import {
  APPLE_STORE_LINK,
  GOOGLE_PLAY_STORE_LINK,
} from "../config/constants/common";
import { useThemeColors } from "../hooks/useThemeColors";

interface ICardProps {
  isMaintenance?: boolean;
  isEvent?: boolean;
  isClosed?: boolean;
  isGovernance?: boolean;
  isMembership?: boolean;
  isAppOnly?: boolean;
  isLockup?: boolean;
  isComingSoon?: boolean;
  symbol?: string;
  img?: string[];
  title?: string;
  subtitle?: string;
  list?: {
    title: React.ReactNode;
    value: React.ReactNode;
    tooltip?: string;
    invisible?: boolean;
  }[];
  onClick: () => void;
  buttonTitle?: string;
}

const Card = (props: ICardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);
  const COLOR_BG_PRIMARY = useColorModeValue("#FFFFFF", "#1A1D28");
  return (
    <GradientBox>
      <Box
        position="relative"
        zIndex={1}
        textAlign="center"
        w="100%"
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        minHeight={"350px"}
        minW={"285px"}
        padding={{ base: "20px", md: "40px 16px 16px 16px" }}
      >
        {/* Badge */}
        {/* {props.isMaintenance && (
          <MaintenanceBadge>{t("card.badge.maintenance")}</MaintenanceBadge>
        )}
        {props.isEvent && (
          <EventBadge>{t("staking.list.eventBadge")}</EventBadge>
        )}
        {props.isClosed && <CloseBadge>{t("지원 종료")}</CloseBadge>}
        {props.isGovernance && (
          <Tooltip
            label={t("staking.list.votingPowerBadgeTooltip1", {
              symbol: props.symbol,
              chainname: t(props.network ?? ""),
              chain: props.network,
            })}
            aria-label="Governance Tooltip"
          >
            <Box position="absolute" top={4} right={4}>
              <GovernanaceBadge />
            </Box>
          </Tooltip>
        )} */}

        {/* Token */}
        <VStack spacing={0} w={"100%"}>
          <TokenIcon img={props.img} margin={"0px -4px"} />
          <Text as="h5" fontSize="24px" fontWeight="bold" whiteSpace={"nowrap"}>
            {props.title}
          </Text>
          <Text as="h6" fontSize="14px" color={"gray.500"}>
            {props.subtitle}
          </Text>
        </VStack>

        {/* KeyValue */}
        <VStack spacing={"8px"} mt={6} width={"100%"}>
          {/* {props.isMembership && <MembershipBadge />} */}
          {props.list?.map(
            ({ title, value, tooltip, invisible }) =>
              !invisible && (
                <StakingKeyValueSet
                  key={title?.toString()}
                  keyName={title}
                  questionTooltip={tooltip}
                  keyFontStyles={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    letterSpacing: "0.07px",
                  }}
                  valueFontStyles={{
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "22px",
                    letterSpacing: "0.07px",
                  }}
                >
                  {value}
                </StakingKeyValueSet>
              )
          )}
        </VStack>

        {/* Button */}
        <ButtonGroup mt={"auto"} w={"100%"}>
          {props.isAppOnly ? (
            <AppButton />
          ) : (
            <Button
              fontSize="15px"
              fontWeight="500"
              lineHeight="20px"
              p="10px 12px"
              bg={"purple.300"}
              onClick={props.onClick}
              w={"100%"}
              onMouseEnter={handleMouseEnter}
              color={COLOR_BG_PRIMARY}
              textStyle={"label3"}
              isDisabled={props.isComingSoon}
            >
              {props.isComingSoon
                ? "Coming Soon"
                : props.buttonTitle ?? " Stake Now"}
            </Button>
          )}
        </ButtonGroup>
      </Box>
    </GradientBox>
  );
};

// const MaintenanceBadge = (props) => (
//   <Badge position="absolute" top={0} left={0} colorScheme="black" {...props} />
// );

// const EventBadge = (props) => (
//   <Badge position="absolute" top={0} left={0} colorScheme="blue" {...props} />
// );

// const CloseBadge = (props) => (
//   <Badge position="absolute" top={0} left={0} colorScheme="black" {...props} />
// );

export default Card;

const AppButton = (props: any) => {
  const { color } = useThemeColors();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      w={"100%"}
      cursor={"pointer"}
    >
      {!isHovered ? (
        <Button
          fontSize="15px"
          fontWeight="500"
          lineHeight="20px"
          p="10px 12px"
          bg={"transparent"}
          color={color.text.primary}
          w={"100%"}
          textStyle={"label3"}
          border={"1px solid"}
          borderColor={color.text.primary}
        >
          {props.buttonTitle ?? " Available at NEOPIN App"}
        </Button>
      ) : (
        <Flex
          width="100%"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="10px"
        >
          <StoreButton
            href={APPLE_STORE_LINK}
            iconPath="M14.2106 16.9C13.3939 17.6917 12.5023 17.5667 11.6439 17.1917C10.7356 16.8083 9.90225 16.7917 8.94392 17.1917C7.74392 17.7083 7.11059 17.5583 6.39392 16.9C2.32725 12.7083 2.92725 6.325 7.54392 6.09167C8.66892 6.15 9.45225 6.70833 10.1106 6.75833C11.0939 6.55833 12.0356 5.98333 13.0856 6.05833C14.3439 6.15833 15.2939 6.65833 15.9189 7.55833C13.3189 9.11667 13.9356 12.5417 16.3189 13.5C15.8439 14.75 15.2273 15.9917 14.2023 16.9083L14.2106 16.9ZM10.0273 6.04167C9.90225 4.18333 11.4106 2.65 13.1439 2.5C13.3856 4.65 11.1939 6.25 10.0273 6.04167Z"
            label="App Store"
          />
          <StoreButton
            href={GOOGLE_PLAY_STORE_LINK}
            iconPath="M4 3.85914V16.1402C4.00008 16.1669 4.00803 16.1929 4.02284 16.215C4.03766 16.2372 4.05868 16.2545 4.08328 16.2647C4.10788 16.275 4.13495 16.2777 4.16111 16.2726C4.18727 16.2676 4.21134 16.2549 4.23031 16.2362L10.625 10L4.23031 3.76321C4.21134 3.7445 4.18727 3.73181 4.16111 3.72673C4.13495 3.72165 4.10788 3.72441 4.08328 3.73465C4.05868 3.7449 4.03766 3.76218 4.02284 3.78433C4.00803 3.80647 4.00008 3.8325 4 3.85914ZM13.3063 7.43753L5.28813 3.02009L5.28313 3.01728C5.14501 2.94228 5.01376 3.12915 5.12688 3.2379L11.4122 9.24782L13.3063 7.43753ZM5.12751 16.7621C5.01376 16.8708 5.14501 17.0577 5.28376 16.9827L5.28876 16.9799L13.3063 12.5625L11.4122 10.7516L5.12751 16.7621ZM16.5432 9.21876L14.3041 7.98565L12.1988 10L14.3041 12.0134L16.5432 10.7812C17.1523 10.4447 17.1523 9.55532 16.5432 9.21876Z"
            label="Google Play"
          />
        </Flex>
      )}
    </Box>
  );
};
const StoreButton = ({
  href,
  iconPath,
  label,
}: {
  href: string;
  iconPath: string;
  label: string;
}) => {
  const { color } = useThemeColors();

  return (
    <Link
      href={href}
      isExternal
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap="3px"
      cursor="pointer"
      height="40px"
      textDecoration="none"
      padding="8px"
      boxSizing="border-box"
      borderRadius="8px"
      border="1px solid"
      borderColor="var(--Color-Border-Secondary)"
      backgroundColor="var(--Color-Icon-Primary)"
      _hover={{ textDecoration: "none" }}
      _focus={{ outline: "none" }}
      width={{ base: "100%", md: "40px" }}
    >
      <Icon viewBox="0 0 20 20" fill="none" boxSize={5}>
        <path d={iconPath} fill={color.text.primary} />
      </Icon>
      <Text
        color="white"
        textDecoration="none"
        fontSize="14px"
        display={{ base: "inline", md: "none" }}
      >
        {label}
      </Text>
    </Link>
  );
};
