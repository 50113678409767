// themeColors.js
import { useColorModeValue } from "@chakra-ui/react";

export const useThemeColors = () => {
  return {
    color: {
      text: {
        black: useColorModeValue("#fff", "#1A1D28"),
        primary: useColorModeValue("#1A1D28", "#fff"),
        secondary: useColorModeValue("gray.800", "gray.800"),
        tertiary: useColorModeValue("gray.600", "gray.600"),
        quaternary: useColorModeValue("gray.500", "gray.500"),
        quinary: useColorModeValue("#C4CCD1 ", "#C4CCD1"),
        info: useColorModeValue("#3182CE", "#3182CE"), // blue-400
        warning: useColorModeValue("#D69E2E", "#D69E2E"), // yellow-400
        success: useColorModeValue("#38A169", "#38A169"), // green-500
        danger: useColorModeValue("#E53E3E", "#E53E3E"), // red-400
        minus: useColorModeValue("gray.500", "gray.50"),
        plus: useColorModeValue("#38A169", "#38A169"), // green-500
        placeholder: useColorModeValue("gray.200", "gray.600"),
      },
      background: {
        primary: useColorModeValue("white", "neutral.1200"),
        secondary: useColorModeValue("#C4CCD1", "#495257"),
        tertiary: useColorModeValue("#EDF2F7", "#4A5568"), // gray-100, gray-800
        quaternary: useColorModeValue("#E2E8F0", "#718096"), // gray-200, gray-700
        infoSubtle: useColorModeValue("#EBF8FF", "#EBF8FF"), // blue-100
        info: useColorModeValue("#3182CE", "#3182CE"), // blue-400
        warningSubtle: useColorModeValue("#FEFCBF", "#FEFCBF"), // yellow-100
        warning: useColorModeValue("#D69E2E", "#D69E2E"), // yellow-400
        successSubtle: useColorModeValue("#F0FFF4", "#F0FFF4"), // green-100
        success: useColorModeValue("#38A169", "#38A169"), // green-500
        dangerSubtle: useColorModeValue("#FFF5F5", "#FFF5F5"), // red-100
        danger: useColorModeValue("#E53E3E", "#E53E3E"), // red-400
        boxSelected: useColorModeValue("#EBF8FF", "#2C5282"), // blue-50, blue-900
        boxSelectedBorder: useColorModeValue("#3182CE", "#2B6CB0"), // blue-400, blue-800
        boxUnselected: useColorModeValue("#F7FAFC", "#4A5568"), // gray-50, gray-900
        boxUnselectedBorder: useColorModeValue("#E2E8F0", "#718096"), // gray-200, gray-800
      },
      border: {
        primary: useColorModeValue("gray.100", "neutral.1000"),
        secondary: useColorModeValue("gray.300", "gray.600"),
        tertiary: useColorModeValue("gray.400", "gray.500"),
        quaternary: useColorModeValue("gray.500", "gray.400"),
        focus: useColorModeValue("#805AD5", "#805AD5"), // purple-400
        info: useColorModeValue("#3182CE", "#3182CE"), // blue-400
        warning: useColorModeValue("#D69E2E", "#D69E2E"), // yellow-400
        success: useColorModeValue("#38A169", "#38A169"), // green-500
        danger: useColorModeValue("#E53E3E", "#E53E3E"), // red-400
        disabled: useColorModeValue("gray.300", "gray.800"),
      },
      icon: {
        primary: useColorModeValue("gray.900", "gray.700"),
        secondary: useColorModeValue("gray.600", "gray.500"),
        tertiary: useColorModeValue("gray.500", "gray.300"),
        quaternary: useColorModeValue("gray.200", "gray.200"),
        info: useColorModeValue("#3182CE", "#3182CE"), // blue-400
        warning: useColorModeValue("#D69E2E", "#D69E2E"), // yellow-400
        success: useColorModeValue("#38A169", "#38A169"), // green-500
        danger: useColorModeValue("#E53E3E", "#E53E3E"), // red-400
        vectorwhite: useColorModeValue("#1A1D28", "white"),
        vectorblack: useColorModeValue("white", "#1A1D28"),
        checkCircleDeactivated: useColorModeValue("gray.200", "gray.700"),
      },
      button: {
        primary: {
          active: useColorModeValue("#7A6EFF", "#7A6EFF"),
        },
        secondary: {
          background: useColorModeValue("gray.500", "gray.300"),
          text: useColorModeValue("white", "gray.800"),
        },
        tertiary: {
          background: useColorModeValue("#3182CE", "#3182CE"), // blue-400
          active: useColorModeValue("#2B6CB0", "#2B6CB0"), // blue-500
          disabled: useColorModeValue("gray.300", "gray.800"),
        },
        quaternary: {
          background: useColorModeValue("#1A1D28", "#fff"),
          active: useColorModeValue("#1A1D28", "#F7FAFC"), // gray-800, gray-50
          disabled: useColorModeValue("gray.300", "gray.800"),
        },
        outline: {
          background: useColorModeValue("gray.500", "gray.500"),
          active: useColorModeValue("gray.800", "gray.800"),
          disabled: useColorModeValue("gray.300", "gray.800"),
        },
        connected: useColorModeValue("white", "#1A1D28"),
      },
      tab: {
        categorySelected: useColorModeValue("#1A1D28", "#fff"),
        categorySelectedBorder: useColorModeValue("gray.500", "gray.500"),
        categoryUnselectedBorder: useColorModeValue("gray.200", "gray.200"),
        underlineSelected: useColorModeValue("#1A1D28", "#fff"),
        underlineUnselected: useColorModeValue("white", "#1A1D28"),
        toggleSelected: useColorModeValue("gray.900", "gray.0"),
        toggleUnselected: useColorModeValue("gray.100", "gray.100"),
        gnbUnderlineSelected: useColorModeValue("#3182CE", "#3182CE"), // blue-400
      },
      label: {
        blue: {
          fill: useColorModeValue("#2A4365", "#2A4365"), // blue-900
          text: useColorModeValue("#EBF8FF", "#EBF8FF"), // blue-100
        },
        lightblue: {
          fill: useColorModeValue("#3182CE", "#3182CE"), // blue-400
          text: useColorModeValue("#1A1D28", "#1A1D28"),
        },
        green: {
          fill: useColorModeValue("#22543D", "#22543D"), // green-900
          text: useColorModeValue("#F0FFF4", "#F0FFF4"), // green-100
        },
        red: {
          fill: useColorModeValue("#742A2A", "#742A2A"), // red-900
          text: useColorModeValue("#FFF5F5", "#FFF5F5"), // red-100
        },
        yellow: {
          fill: useColorModeValue("#744210", "#744210"), // yellow-900
          text: useColorModeValue("#FEFCBF", "#FEFCBF"), // yellow-100
        },
        gray: {
          fill: useColorModeValue("gray.600", "gray.600"),
          text: useColorModeValue("#1A1D28", "#1A1D28"),
        },
        darkgray: {
          fill: useColorModeValue("gray.200", "gray.900"),
          text: useColorModeValue("#1A1D28", "#F7FAFC"), // neutral-1200, gray-50
          icon: useColorModeValue("#1A1D28", "#F7FAFC"), // neutral-1200, gray-50
        },
        white: {
          fill: useColorModeValue("#1A1D28", "#fff"), // neutral-1200, white
          text: useColorModeValue("#fff", "#1A1D28"),
        },
        black: {
          fill: useColorModeValue("#1A1D28", "#1A1D28"), // neutral-1200
          text: useColorModeValue("#EBF8FF", "#EBF8FF"), // gray-100
        },
      },
      gradient: {
        cardBg: useColorModeValue(
          "linear-gradient(149.26deg, #88b8f7 0%, #ffffff 22.65%, #f3f8fb 98.5%)",
          "linear-gradient(149.26deg, #353a68 0%, #202127 22.65%, #1a1d33 98.5%)"
        ),
        cardBorder: useColorModeValue(
          "linear-gradient(146.43deg, #f2f7fa 8.28%, #ffffff 54.06%)",
          "linear-gradient(153.28deg, var(--Color-Bg-Primary) 22.47%, #2c2e39 100%)"
        ),
      },
      derivativesBg: {
        blue: useColorModeValue("#0c2d4b0d", "#0c2d4b"),
        gray: useColorModeValue("#2232400d", "#223240"),
      },
      noti: {
        red: useColorModeValue("#c93030", "#721a1a"),
      },
    },
  };
};
