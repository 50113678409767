export * from "./api-info-status";

// 0 서비스 종료(*), 1:서비스 진행중,  2 : 서비스 시작 예정(**),  3 : 서비스 임시 중지, 4 : 서비스 종료 예정
// 4이면 스테이킹 같은 경우 입금은 안되고 출금만 되는 상태
// * 표시는 화면에 안보임.
// ** 시작예정은 Total Staked와 APY가 TBD로 표시됨.
export enum StatusType {
  END = 0,
  ING = 1,
  READY = 2,
  PAUSE = 3,
  WILL_END = 4,
}
export interface ProductResponse {
  result: number;
  resultString: string;
  desc: string;
  staking: StakingItem[];
  derivative: DerivativeItem[];
  liquidStaking: StakingItem[];
  index: IndexItem[];
}

// BaseItem 인터페이스: 공통된 필드 정의
export interface BaseItem {
  chain: string;
  name: string;
  cate: string;
  displayName: LanguageDisplayName;
  stat: StatusType;
  isWebSupported: boolean;
  contractAddress?: string; // StakingItem에서는 stakingAddress, DerivativeItem과 IndexItem에서는 contractAddress 사용
  aprRate: number;
  imageURL: string[];
}

// StakingItem 인터페이스: BaseItem 확장 및 추가 필드 정의
export interface StakingItem extends BaseItem {
  isBonus: number;
  stakingAddress: string; // stakingAddress는 StakingItem에만 존재
  stakedAmount: number;
  stakeEnable: number;
  unstakeEnable: number;
  minStakeAmount: number;
  minUnStakeAmount: number;
  stakeToken: TokenInfo;
  rewardInfo: RewardInfo;
  isMembershipItem: boolean;
  membershipBonusInfo: MembershipBonusInfo;
  maticStakingInfo?: MaticStakingInfo;
  contractStakingInfo?: ContractStakingInfo;
  stakingOrder?: number;
}

// DerivativeItem 인터페이스: BaseItem 확장 및 추가 필드 정의
export interface DerivativeItem extends BaseItem {
  description: string;
  contractAddress: string; // DerivativeItem에만 존재
  order: number;
  depositEnable: number;
  withdrawEnable: number;
  apyRate: number;
  protocol: Protocol[];
  claimableEstimateDay: ClaimableEstimateDay;
  depositToken: DepositToken[];
  liquidToken: LiquidToken[];
  rewardToken: TokenInfo[];
}

// IndexItem 인터페이스: BaseItem 확장 및 추가 필드 정의
export interface IndexItem extends BaseItem {
  description: string;
  contractAddress: string; // IndexItem에만 존재
  order: number;
  depositEnable: number;
  withdrawEnable: number;
  apyRate: number;
  price: number;
  priceChange24H: number;
  priceChange24HRate: number;
  protocol: Protocol[];
  depositToken: DepositToken[];
  liquidToken: LiquidToken[];
  rewardToken: TokenInfo[];
  allocationToken: AllocationToken[];
}
interface LanguageDisplayName {
  ko: string;
  en: string;
  ja: string;
}

interface TokenInfo {
  name: string;
  symbol: string;
  decimal: number;
  chain: string;
  address: string;
  currencyId: string;
}

interface RewardInfo {
  rewardToken: TokenInfo;
  maxBonusApr: number;
  minBonusApr: number;
  userBonusApr: number;
  rewardApr?: number;
  minClaimAmount?: number;
}

interface MembershipBonusInfo {
  bonusToken: TokenInfo;
  bonusAmount: number;
  maxBonusApr: number;
  minBonusApr: number;
  userBonusApr: number;
}

interface MaticStakingInfo {
  approveAddress: string;
}

interface ContractStakingInfo {
  pid: number;
}

interface Protocol {
  name: string;
  provider: string;
  description: string;
  product: string;
  imageUrl: string;
  type: string;
  url: string;
  apr: number;
  apy: number;
  tokenInfo: TokenInfo;
}

interface ClaimableEstimateDay {
  min: number;
  max: number;
}

interface DepositToken {
  currencyId: string;
  groupId: string;
  chain: string;
  decimal: number;
  symbol: string;
  address: string;
  imageUrl: string;
  minAmount: number;
}

interface LiquidToken {
  currencyId: string;
  groupId: string;
  chain: string;
  decimal: number;
  symbol: string;
  address: string;
  imageUrl: string;
  type: string;
  price?: number;
}

interface AllocationToken {
  currencyId: string;
  groupId: string;
  chain: string;
  decimal: number;
  symbol: string;
  address: string;
  imageUrl: string;
  allocation: number;
}
